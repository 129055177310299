import localeAntd from "ant-design-vue/lib/locale-provider/fr_FR";

const messages = {
  all: {
    okay: "OK",
    add: "Ajouter",
    edit: "Modifier",
    cancel: "Annuler",
    save: "Enregistrer",
    sureToCancelAlert: "Êtes-vous sûr d'annuler?",
    sureToDelete: "Voulez-vous supprimer?",
    startDate: "Date de début",
    endDate: "Date de fin",
    requiredStartDate: "La Date de début est requise",
    requiredEndDate: "La Date de fin est requise",
    requiredName: "Le nom est requis",
    requiredLevel: "Le niveau est requis!",
    enterInformationBelow: "Entrez les informations dans les champs ci-dessous",
    oui: "Oui",
    non: "Non",
    and: "Et",
    filterBy: "Recherche",
    filterByAddedDate: "Recherche par date d'ajout",
    sureTo: "Êtes-vous sûr?",
    unsavedchanges: "Modifications non enregistrées !",
    unsavedchangesContent:
      "Voulez-vous vraiment partir ? Vous avez des changements non enregistrés !",
    savedChanges: "Les modifications ont été enregistrées avec succès",
    typeNeeded: "Types de fichiers autorisés : png, jpg, jpeg.",
    infoConx: "Informations de connexion",
    lienConx: "Lien de connexion",
    lienConxText: "ou application Mobile EdTeacher",
  },

  teacherAvailability: {
    available: "Je suis disponible",
    notAvailable: "Je ne suis pas disponible",
    saved: "La disponibilité a été enregistrée avec succès",
    notFilledYet: "L'enseignant n'a pas encore saisi la fiche de disponibilité",
  },

  smartSchedule: {
    generalParams: "General",
    comment: "Commentaire",
    commentRequired: "Le commentaire est requis",
    startDay: "Jour de début",
    endDay: "Jour de fin",
    workingTime: "Temps de travail",
    breakingTime: "Temps de pause",
    startAt: "Commencer à",
    endAt: "Terminer à",
    scheduleParmsAdded: "L'emploi a été ajouté avec succès",
    generalParamsSaved:
      "Les paramètres généraux ont été enregistrés avec succès",
    selectSchedule: "Sélectionner le paramétrage",
    subjectsSchedParam: "Les matières",
    weeklyRate: "Taux horaire par semaine",
    module: "Module",
    subject: "Matière",
    moduleSpecific: "Module pilote",
    teacherClassParam: "Affectation des enseignants",
    teacherAffectedSuccess: "L'enseignant a été affecté avec succès",
    teachersWeeklyRate: "Taux horaire des enseignants",
    name: "Nom du paramétrage",
    smartSchedule: "Emploi intelligent",
    smartSchedules: "Emplois intelligent",
    ready: "Prêt",
    pending: "En attente",
    finished: "Terminé",
    error: "Erreur",
    addParam: "Ajouter un paramétrage",
    migrateSuccess: "Les emplois a été migré avec succès",
    migrate: "Migrer les emplois",
    unmigrated: "Les emplois ont été restaurés avec succès",
    noDataToUnmigrate: "Les emplois contiennent déjà des sessions manuelles !",
    unmigrate: "Restaurer les sessions manuelles",
    minDuration: "Durée minimale",
    maxDuration: "Durée maximale",
    fillAllClassroomsToGenerate:
      "Veuillez remplir toutes les classes pour générer les emplois",
    weeklyRateMaxError:
      "Le taux horaire par semaine doit être égal ou supérieur à la durée maximale",
    weeklyRateMinError:
      "Le taux horaire par semaine doit être égal ou supérieur à la durée minimale",
  },

  calender: {
    month: "Mois",
    week: "Semaine",
    day: "Jour",
    addEvent: "Ajouter un événement",
    addEventTitle: "Ajouter un nouvel événement",
    duration: "Durée",
    durationRequired: "La durée est requise",
    exam: "Examen",
    party: "Fête",
    excursion: "Excursion",
    meeting: "Réunion",
    other: "Autre",
    eventType: "Type d'événement",
    color: "Couleur",
    typeRequired: "Le type d'événement est requis",

    editEventTitle: "Modifier l'événement",
    editEvent: "Modifier l'événement",
    editedEvent: "L'événement a été modifié avec succès",
    deletedEvent: "L'événement a été supprimé avec succès",
    addedEvent: "L'événement a été ajouté avec succès",
    meetingBetweenTeachersAdvisors:
      "Réunion entre les enseignants et les conseillers pédagogiques",
    createdBy: "Créé par le conseiller",
  },

  chat: {
    ROOMS_EMPTY: "Aucune conversation",
    ROOM_EMPTY: "Aucune conversation sélectionnée",
    NEW_MESSAGES: "Nouveaux messages",
    MESSAGE_DELETED: "Ce message a été supprimé",
    MESSAGES_EMPTY: "Aucun message",
    CONVERSATION_STARTED: "La conversation a commencée le :",
    TYPE_MESSAGE: "Tapez votre message",
    SEARCH: "Rechercher",
    IS_ONLINE: "est en ligne",

    parentDetails: "Détails du parent",
    startNewConversationWithParent:
      "Créer nouvelle conversation avec un parent",
    addParent: "Ajouter un parent",
    deleted: "Supprimé",
  },

  paymentStatus: {
    paymentStatus: "Statut des paiement",
    paidAmount: "Le montant payé",
    monthRemainingPayments: "Paiements restants ({month})",
    allRemainingPayments: "Tous les paiements restants",
    noRemainingPyaments: "Il n'y a paiements restants",
    nothingPaid: "Il n'y a pas de paiements payés",
  },

  language: {
    ar: "Arabe",
    fr: "Français",
    en: "Anglais",
  },

  appreciation: {
    adminAppreciation: "Appréciation de l'admin",
    withOptions: "Appréciations spécifiées",
    manual: "Appréciations manuelles",
    appreciation: "Appréciation",
    add: "Ajouter une appréciation",
    changedToManual: "Le mode d'appréciation est passé à manuel",
    changedToOptions: "Le mode d'appréciation est passé à spécifié",
    teacherManualDesc:
      "L'enseignant a accès à écrire quoi que ce soit dans l'appréciation",
    saved: "L'appréciation a été enregistrée avec succès",
    deleted: "L'appréciation a été supprimer avec succès",
  },

  feedback: {
    sendFeedback: "Envoyer un commentaire",
    category: "Catégorie",
    comment: "Commentaire",
    problem: "Probléme/bug",
    idea: "Idée d'amélioration",
    others: "Autre",
    section: "Section",
    selectSection: "Veuillez sélectionner la section",
    priority: "Priorité",
    selectPriority: "Veuillez choisir la priorité",
    low: "Basse",
    meduim: "Moyenne",
    high: "Haute",
    critical: "Critique",
    message: "Message",
    uploadImages: "Importer des images",
    uploadAudio: "Enregistrement audio",
    record: "Enregistrer",

    successSent:
      "Merci pour votre contribution. Nous allons l'examiner et vous recontacter si besoin. Bonne journée !",
  },

  certification: {
    add: "Ajouter un certificat",
    default: "Utiliser les certificats par défaut",
    confirmDefault:
      "Cette action supprimera votre certification. Êtes-vous sûr de le définir par défaut ?",
    name: "Nom du certificat",
    min: "Moyenne minimale",
    max: "Moyenne maximale",
    minError:
      "La moyenne minimale ne peut pas être supérieure à la moyenne maximale",
    maxError:
      "La moyenne maximale ne peut pas être inférieure à la moyenne minimale",
    defaultDone: "Les certificats ont été mis à jour avec succès",
    moyenne: "Moyenne",
  },

  decisionConseil: {
    decision: "Décision",
    decisionM: "Décision (garçon)",
    decisionF: "Décision (fille)",

    decisionConseil: "Décisions du conseil",
    default: "Utiliser les décisions par défaut",
    defaultDone: "Les décisions du conseil ont été mis à jour avec succès",
    confirmDefault:
      "Cette action supprimera vos décisions du conseil. Êtes-vous sûr de le définir par défaut ?",
  },

  notifications: {
    desactivation:
      "Compte a été suspendu ! Contacter l'administration pour plus d'information !",
    desactivateBuilding:
      "Votre établissement a été suspendu ! Contacter l'administration pour plus d'information !",
    updateuser: "Compte Modifié ! veuillez reconnecter!",
    updateAccess: "Accès Modifié ! veuillez reconnecter!",
  },

  login: {
    incorrectInfo: "Le nom d'utilisateur ou mot de passe est incorrect",
    bienvenue: "Bienvenue à",
    title: "Système de gestion pour les établissements scolaires",
    connectezVous: "Connectez-vous !",
    connextion: "Connexion",
    requisMdp: "Veuillez saisir votre mot de passe!",
    requisUsername: "Veuillez saisir votre username!",
    username: "Nom de l'utilisateur",
    password: "Mot de passe",
    connecte: "Connecté",
    loginSuccess: "Vous vous êtes connecté avec succès",
    readAndAccept: "j'ai lu et j'accepte les",
    termsAndConditions: "Termes et Conditions",
    subscribePart1:
      "Si votre école n'est pas encore inscrite, découvrez nos offres exclusives sur notre ",
    subscribePart2: "site web",
    subscribePart3: "et rejoignez notre communauté éducative dès aujourd'hui !",
  },

  niveau: {
    niveauPre: "Préscolaire",
    niveau0: "Préparatoire",
    niveau1: "1 ère année",
    niveau2: "2 ème année",
    niveau3: "3 ème année",
    niveau4: "4 ème année",
    niveau5: "5 ème année",
    niveau6: "6 ème année",
    //college
    niveau7: "7 ème année",
    niveau8: "8 ème année",
    niveau9: "9 ème année",
    //lycee
    niveau10: "1 ère année secondaire",
    niveau11: "2 ème année secondaire",
    niveau12: "3 ème année secondaire",
    niveau13: "4 ème année secondaire",
    autres: "Autres",
  },

  assiduite: {
    assiduite: "Assiduité",
    retard: "Retard",
    present: "Présent",
    absence: "Absence",
    assiduiteMSA: "Assiduité a été mise à jour",
    assiduiteMoisChart: "Assiduité ce mois-ci de classe { className }",
    nb_absent: "Nombre d'absences",
    nb_delay: "Nombre de retards",
    date: "Date",
    areYouSureToEdit: "Êtes-vous sûr de vouloir modifier la assiduité ?",
  },

  avance: {
    ajoutAvance: "Ajouter une avance",
    ajoutAvanceSubTitle:
      "Veuillez remplir les champs ci-dessous pour ajouter une avance.",
    montantAvance: "Montant avance",
    impoAnnulerAvance: "Impossible d'annuler l'avance",
    typeRecetteRequis: "Le type de recette est requis !",
    saisirChequeInfo: "Saisir les informations du chèque",
    saisirChequeEspaceInfo: "Saisir les informations du chèque et espéce",
    chequeNumRequis: "Le numéro du chèque est requis !",
    historiqueAvances: "Historique des avances",
    avanceAjoute: "Avance ajoutée avec succès",
  },

  profil: {
    parentSuggestionTitle: "Parents qui ont le même numéro de téléphone",
    historique: "Historique",
    paiement: "Paiement",
    payes: "Payés",
    nonPayes: "Non payés",
    bulletin: " Bulletin",
    qrCode: "Qr code",
    informations: "Informations",
    Control: "Contrôle parentale ",
    paiementScol: "Paiement scolarité",
    paiementAct: "Paiements activités",
    listeReglements: "Liste de réglements",
    sexe: "Sexe",
    nom: "Nom ",
    prenom: "Prénom",
    fullname: "Nom et prénom",
    dateNaissance: "Date de naissance",
    garcon: "Garçon",
    fille: "Fille",
    adresse: "Adresse",
    tel: "Téléphone",
    classe: "Classe",
    notes: "Notes",
    nonInscrit: "N'est pas encore inscrit",
    nomUser: "Nom d'utilisateur",
    mdp: "Mot de passe",
    gere: "Gérer",
    consulter: "Consulter",
    consulterPaiement: "Consulter Paiement",
    etat: "Etat",
    mois: "Mois",
    etablissement: "Etablissement",
    ajouteLe: "Ajouté le",
    lastModifi: "Dernière modification",
    annulerInscri: "Annuler l'inscription",
    changerPrametreParent: "Changer les paramètre du parent",
    changerPrametreEleve: "Changer les paramètre du élevé",
    inscriActivitePour: "Inscription activité pour {name}",

    poid: "Poid",
    taille: "Taille",
    maladies: "Maladies",
    remarques: "Remarques",
    selectOption: "Au moins sélectionner une option",
    aucuneMaladieEnregistree: "Aucune maladie a été enregistrée",
    aucuneDossierMedical: "Aucun dossier médical est trouvé!",

    annulationEleve: "Annulation d'inscription",
    deleteEleve: "Suppression élève",
  },
  topBar: {
    issuesHistory: "Histoire des problèmes",
    projectManagement: "Gestion de projet",
    typeToSearch: "Chercher...",
    findPages: "Trouver des pages...",
    actions: "Actes",
    status: "Statut",
    profileMenu: {
      hello: "Bonjour",
      billingPlan: "Plan de facturation",
      role: "Rôle",
      email: "Email",
      phone: "Téléphone",
      editProfile: "Editer le profil",
      logout: "Connectez - Out",
    },
  },

  menu: {
    // smart timetable
    accessTeacher: "Accès des enseignants",
    parametrageEtablissement: "Paramétrage de l'établissement",
    parametrageComptabilite: "Paramétrage de la comptabilité",
    parametrageScolarite: "Paramétrage de la scolarité",
    smartSchedule: "Emploi intelligent",
    teacherAvailability: "Dispo enseignants.",
    calender: "Calendrier",
    cheques: "Les chèques",
    chat: "Chat",
    supervision: "Supervision",
    ptchat: "Chat enseignant et parent",
    logout: "Se déconnecter",
    activites: "Activités",
    etablissement: "Etablissement",
    generale: "Générale",
    accueil: "Accueil",
    parametrage: "Paramétrage",
    actualite: "Actualité",
    administration: "Administration",
    autorisation: "Autorisation",
    utilisateur: "Utilisateur",
    liste: "Liste des élèves",
    cantine: "Menu cantine",
    etatPaiement: "Etat de paiement",
    inscriActiv: "Inscription : Activités",
    scolarite: "Scolarité",
    inscriScol: "Inscription : Scolarité",
    etatPaiements: "Etat des paiements",
    listePunition: "Liste des punitions",
    emploi: "Emploi du temps",
    eleves: "Élèves",
    enseignants: "Enseignants",
    bulletin: "Bulletin",
    bulletinStatistique: "Bulletin statistique",
    notes: "Notes",
    notesModule: "Notes par matière",
    notesParEleve: "Notes par élève",
    notesModuleComplet: "Notes par module",
    personnel: "Personnel",
    listePersonnels: "Liste des personnels",
    listeEnseignants: "Liste des enseignants",
    listeActivites: "Liste des activités",
    virementBancaire: "Virement Bancaire",
    listeEleves: "Liste des élèves",
    comptabilite: "Comptabilité",
    etatRecettes: "Etat des recettes",
    journaleCaisse: "Journal Caisse",
    depenses: "Dépenses",
    depensesPersonnel: "Dépenses salariales",

    etatImpayes: "Etat des impayés",
    listeReglement: "Liste réglement",
    reglementsAnnules: "Réglements annulés",
    etatImpayesActivites: "Etat impayés : Activités",
    etatImpayesScolarite: "Etat impayés : Scolarité",
    declaration: "Déclaration",
    declarationTva: "Déclaration TVA",
    chiffreAffaires: "Chiffre d'affaires",
    chiffreAffairesScolarite: "Chiffre d'affaires : Scolarité",
    statistique: "Statistique",
    administration: "Administration",
    actulite: "Actualité",
    sms: "SMS",
    envoyerSms: "Envoyer SMS",
    envoyerSmsLogin: "Connexion parents",
    envoyerParClasseSms: "Envoyer par classe",
    historiqueSms: "Historique",
    caisseAvance: "Caisse avance",
    assiduite: "Assiduités",
    preinscription: "Pré-inscription",

    profile: "Mon Profile",

    certificate: "Certificat",
  },

  accueil: {
    activite: "Activité",
    eleves: "Elèves",
    classes: "Classes",
    serviceActiv: "Services / Activités",
    benefice: "Recette totale",
    montant: "Montant",
    pourcentage: "POURCENTAGE",
    depense: "Dépense totale",
    RevenuDep: "Dépenses / Revenu ",
    depenses: "Dépenses",
    revenu: "Revenu",
    tnd: "TND",
  },

  action: {
    reinitialiser: "Réinitialiser",
    clone: "Dupliquer",
    reset: "Réinitialiser",
    imprimerTab: "Imprimer le tableau",
    imprimerBadges: "Imprimer les badges",
    imprimerNewBadges: "Imprimer les badges avec photo",
    simpleBadge: "Badge simple",
    badgeAvecPhoto: "Badge avec photo",
    actions: "Actions",
    supprimer: "Supprimer",
    ajouter: "Ajouter",
    modifier: "Modifier",
    consulter: "Consulter",
    annuler: "Annuler",
    enregistrer: "Enregistrer",
    afficher: "Afficher",
    voir: "Voir",
    desactiver: "Désactiver",
    activer: "Activer",
    imprimer: "Imprimer",
    confirmer: "Confirmer",
    changer: "Changer",
    importer: "Importer",
    preview: "Aperçu",
  },

  sms: {
    paymentRappel: "Rappel de paiement",
    deatilsAutoSmsTitle: "Détails des messages SMS automatiques",
    detailsAutoPnTitle: "Détails des notifications mobiles automatiques",
    deatilsAutoSms: "Plus de détails",
    newPayment: "Nouveau paiement",
    newInscription: "Nouvelle inscription",
    newAbsence: "Absence/retard",
    newCourse: "Cours/Exercice",
    bulletinDispo: "Bulletin disponible",
    actualite: "Actualité",
    autoSms: "Paramètres de SMS automatiques",
    autoPn: "Paramètres de notification mobile",
    votreSolde: "VOTRE SOLDE",
    nbrSmsRestant: "Nombre d'SMS restant",
    nomlExpediteur: "NOM DE L'EXPÉDITEUR",
    desclExpediteur: "Les SMS sont envoyés en tant que",
    gererListeDistinateurs: "GÉRER LA LISTE DES DESTINATEURS",
    envoyerDesSms: "ENVOYER DES SMS",
    listeVosEleves: "Liste de vos élèves",
    listeClasses: "Liste des classes",
    selectionnerEleves: "Sélectionner des élèves",
    selectionnerClases: "Sélectionner des classes",
    pressEnterToSelect: "Appuyez sur Entrée pour sélectionner",
    entrerNumerosSpecifiques: "Entrer des numéros spécifiques",
    entrerNumerosSpecifiquesDesc:
      "Les numéros de téléphone doit être séparés par des virgules",
    categorieTag: "Catégorie/tag",
    categorieTagDesc: "Les tags vous aide à bien organiser vos messages",
    saisirCategorieTag: "Saisir des catégories/tags",
    contenueVotreMessage: "Saisir la contenue de votre message",
    contenueMessage: "Saisir le contenu de l'SMS",
    listeFinale: "LISTE FINALE",
    nombreSms: "Nombre des Sms",
    nombreMessage: "Nombre de messages",
    nomberTotal: "Nombre total",
    nombreMessageParCategorie: "Nombre des messages par catégorie",
    nombreCaracteres: "NOMBRE DES CARACTÈRES",
    pere: "Père",
    mere: "Mère",
    specifique: "Spécifique",
    destinataire: "Destinataire",
    telephone: "Téléphone",
    parents: "parents",
    personnel: "personnel",
    sanction: "sanction",
    bulletin: "bulletin",
    envoyer: "Envoyer",
    nonInscrit: "Non inscrit",
    noOptions: "La liste est vide",
    jours: "Jours",
    boiteEnvoieSms: "Boite d'envoie des SMS",
    message: "Message",
    numsSpecifiques: "Numéros spécifiques",
    listeParents: "Liste des parents",
    envoyeParUser: "Envoyé par l'utilisateur",
    groupe: "Groupe",
    tags: "Tags",
    envoiDate: "Date envoyée",
    selectAll: "Sélectionner tous",
    //send logins
    envoyerDesSmsLogins: "ENVOYER DES SMS DES LOGINS",
    alertNumberSMS:
      "Si le nom d'utilisateur et le mot de passe sont longue (dépasse 22 caractères) le nombre d'SMS pour un parent peut consommer 2 SMS du solde",
  },

  actualite: {
    ajouterAlbum: "Ajouter un album",
    ajouterActualite: "Ajouter une actualité",
    date: "Date de sortie",

    tousClasses: "Tous les classes",
    titre: "Titre",
    categorie: "Catégorie",
    cibles: "Cibles",
    jaimes: "J'aimes",
    photos: "Photos",
    dateCreation: "Date de création",
    tous: "Tous",
    enseignants: "Enseignants",
    eleves: "Elèves",
    teachers: "Enseignants",
    students: "Elèves",
    administration: "Administration",
    parents: "Parents",
    cliquer:
      "Cliquez ou faites glisser le fichier vers la zone pour le télécharger",
    support:
      "Prise en charge d'un téléchargement unique ou groupé. Interdire strictement de télécharger des données d'entreprise ou d'autres fichiers de groupe",
    description: "Description",
    ajouterPub: "Ajouter une publication",
    trimestre1: "Triméstre 1",
    trimestre2: "Triméstre 2",
    trimestre3: "Triméstre 3",
    trimester_one: "Trimestre d’origine",
    trimester_two: "Trimestre destination",
    retour: "Retour",
    pasImages: "Pas d'images",
    mentionJaimes: "Mentions de j'aime",
    modifierImages: "Modifier les images",
    specificFor: "Spécifique pour",
    levels: "Niveaux",
    classrooms: "Classes",
    students: "Élèves",
    specified: "Spécifié",
    specifiedInfo:
      "Les filtres d'envoie s'appliqueront seulement pour les parents",
  },

  etablissement: {
    reductionType: "Type de réduction",
    pourcentage: "Pourcentage",
    dinar: "Dinar",
    access: "Accès",
    editAccess: "Modifier l'accès",
    accessUpdated: "L'accès a été modifié avec succès",
    editingAccess: "Modification de l'accès",
    config: "Configurations",
    editConfig: "Modifier la configuration",
    accessConfig: "la configuration a été modifié avec succès",
    editingConfig: "Modification de la configuration",
    alertDisableChat:
      "Activer ou désactiver le chat entre les parents et les enseignants (si désactivé, les enseignants et les parents perdront instantanément l'accès au chat dans les applications mobiles et Web)",
    etablissements: "Etablissements",
    classe: "Classe",
    classes: "Classes",
    salle: "Salles",
    services: "Services",
    activites: "Activités",
    typeQualification: "Type Qualifications",
    anneeScolaire: "Année Scolaire",
    logo: "Logo",
    nom: "Nom",
    directrice: "Directeur/Directrice",
    dateEnregistrement: "Date d'enregistrement",
    telephone: "Téléphone",
    adresse: "Adresse",
    type: "Type",
    niveau: "Niveau",
    fraisInscri: "Frais d'inscription",
    fraisMensuel: "Frais mensuels",
    emploi: "Emploi",
    dateCreation: "Date de création",
    capacite: "Capacité",
    fournisseurs: "Les fournisseurs",
    fournisseur: "Fournisseur",
    fournisseurNonRequis: "Fournisseur (non requis) :",
    service: "Service",
    designation: "Désignation",
    responsable: "Responsable",
    responsableActivite: "Responsable de l'activité",
    ordrePriorite: "Ordre/priorité",
    order: "Order",
    dateDebut: "Date de début",
    dateFin: "Date de fin",
    seuleFois: "Une seul fois",
    entrerInfo: "Entrez les informations dans les champs ci-dessous",
    modifierEmploi: "Modifier l'emploi pour {name}",
    other: "Autre",
    editAlert:
      "La modification de l'année scolaire n'engendre pas une modification sur les mois d'inscription des élèves déjà inscrits, vos étes sûrs de modifier ?",
    clickOrDragToUpload:
      "Cliquez ou faites glisser un fichier dans cette zone pour le télécharger",
    clickForUploadLogo: "Clicker pour modifier le logo",
    ecole: "École primaire",
    college: "Collége",
    lycee: "Lycée",
    collegeEtLycee: "Collége & Lycée",
    primaireEtCollege: "Primaire & Collége",
    jardinEnfant: "Jardin d'enfant",
    cache: "Caché",
    choisir: "Choisir",
    matriculeFiscale: "Matricule fiscale",
    ArabicName: "Nom en Arabe",
    delegeRegional: "Délégué régional",
  },

  comptabilite: {
    comptabilite: "Comptabilité",
    fournisseur: "Fournisseur",
    typeDepense: "Type Dépense",
    typeRecette: "Type Recette",
    nom: "Nom",
    adresse: "Adresse",
    telephone: "Téléphone",
    email: "E-mail",
    codeTVA: "Code TVA",
  },
  scolarite: {
    affichageParametrage: "Paramètre d'affichage",
    matieres: "Matières",
    modules: "Les modules",
    modulesSpec: "Les modules pilote",
    nomModule: "Nom module",
    affectationDansClasses: "Affectation dans les classes",
    affectationEnseignants: "Affectation des enseignants",
    enseignants: "Enseignants",
    typePunitions: "Type de punitions",
    nom: "Nom",
    coefficient: "Coefficient",
    listeMatieres: "Liste des matières",
    listeModules: "Liste des modules",
    infoModule:
      "Affecté des matiéres a des modules a fin d'organiser les gestion des notes !",
    listeModulesSpec: "Liste des modules pilote",
    infoModuleSpec:
      "Affecté des matiéres a des modules pilote a fin d'organiser les gestion des notes !",
    ajouterModule: "Ajouter un module ",
    ajouterMatiere: "Ajouter une matière",
    affectes: "Affectés",
    nonAffectes: " Non Affectés",
    recherche: "Recherche",
    element: "élément",
    aucunDonnee: "Aucun Donnée",
    modifierListeMatieres: "Modifiez la liste des matiéres du module : {name}",
    modifierListeEnseignants:
      "Modifiez la liste des enseignants de la classe : {name}",
    supprime: "Supprimé",
    charcherParClasse: "Chercher par nom de classe",
    confirmDeleteModule:
      "Toutes les sessions relatives à ce module seront supprimées. Ce module est utilisé dans les classes ci-dessous",
    certification: "Certificat",
    appreciation: "Appréciation",
    modulesVerySpec: "Module spécifique",
    subsubjects: "Les sous-matières",
    subsubject: "Sous-matière",
    ajouterSubsubject: "Ajouter une sous-matière",
    addNewSubsubject: "Gérer les sous-matières",
    choisirSubsubject: "Choisir une sous-matière",
    mark: "Note maximale",
    listeModulesVerySpec: "Liste de modules spécifiques",
    infoModuleVerySpec:
      "Affecté des matiéres a des modules spécifique a fin d'organiser les gestion des notes !",
    totalMarksMustBe20:
      "{subject} : le total des notes des sous-matières doit être de 20",
    subjectDejaExiste: "Cette matière existe déjà",
    SubModuleDejaExiste: "Ce sous-module existe déjà",
    cloneParam: "Dupliquer un paramétrage",
    selectParamToClone: "Sélectionner le paramétrage à dupliquer",
    duppModules: "Dupliquer",
  },
  autorisation: {
    accueil: "accueil",
    admin: "Admin",
    designation: "Désignation",
    compteAdmin: "Compte Admin",
    dossierMedical: "Dossier médical",
    listeEleves: "Liste des élèves",
    parametrage: "Paramétrage",
    activite: "Activité",
    administration: "Administration",
    scolarite: "Scolarité",
    personnel: "Personnel",
    personnels: "Personnels",
    comptabilite: "Comptabilité",
    declarations: "Déclarations",
    paiements: "Paiements",
    medicalNote: "Note médicale",
    medicalNoteContext:
      "Les parents peuvent consulter et modifier les notes médicales de leurs enfants.",
  },

  utilisateur: {
    listeUtilisateur: "Liste des Utilisateurs",
    nom: "Nom",
    role: "Rôle",
    acces: "Accès aux établissements",
    actif: "Actif",
    nonActif: "Non actif",
    mdp: "Mot de passe",
    activerDesactiver: "Activer/Désactiver",
    confirmerMdp: "Confirmer mot de passe",
    changerMdp: "Vous pouvez changer la mot de passe",
    active: "Activé",
    nonActive: "Non activé",
    desactiverAlert: "Voulez-vous désactiver cet utilisateur ?",
    activerAlert: "Voulez-vous Activer ce utilisateur?",
    userDesactive: "Utilisateur désactivé!",
    userActive: "Utilisateur activé!",
  },

  liste: {
    listeElevesArchive: "Liste des élèves archivés",
    reactive: "Réactiver",
    reasonArchive: "Raison d'archive",
    addFiles: "Ajouter des fichiers",
    studentPhoto: "Avatar de l'élève",
    studentFiles: "Dossiers d'élève",
    consulterProfil: "Consulter le profil pour plus de détails",
    nomPrenom: "Nom et prénom",
    scolarite: "Scolarité",
    fichesrent: "Fiche Parent",
    attestation: "Attestation de présence",
    inscription: "Inscription",
    supprimerAnnuler: " Supprimer / Annuler",
    inscriSupprimer: "Inscri / Supprimer",
    filles: "Filles",
    garcons: " Garçons",
    total: "Total",
    nonInscrits: "Non-inscrits",
    inscrits: " Inscrits",
    parents: "Parents",
    ajouterEleve: " Ajouter un élève",
    ajouterDossier: "Ajouter un dossier",
    ajouterPersonnel: "Ajouter un personnel",
    ajouterEnseignant: "Ajouter un enseignant",
    listeEleves: "Liste des élèves",
    administration: "Administration",
    nom: "Nom",
    prenom: "Prénom",
    notes: "Notes",
    dateNaissance: "Date de Naissance",
    sexe: "Sexe",
    adresse: "Adresse",
    telephone: "Téléphone",
    classes: "Les classes",
    ajouterParent: "Ajouter nouveau parent",
    parentsExis: "Les parents existants",
    ficheParents: "Fiche parents",
    nomMere: " Nom de la mère",
    prenomMere: "Prénom de la mère",
    telMere: "Téléphone de la mère",
    nomPere: " Nom du père",
    prenomPere: "Prénom du père",
    telPere: "Téléphone du père",
    metierPere: "Métier du père",
    metierMere: "Métier de la mère",
    adresseParents: "Adresse des parents",
    emailParents: "E-mail des parents",
    reduction: "Réduction sur le frais d'inscription",
    reductionMensuel: "Réduction sur le frais mensuel",
    identifiantUnique: "Identifiant unique",
    regionalEducation: "Commissariat Régional de l'education",
    recuEn: "Reçu en",
    nonInscrit: "NON INSCRIT",
    changementNonEffectue: "Changement non effectué",
    champsVides: "Les champs de saisis ne peut pas être vide",
    imprimerLoginsEnsg: "Logins enseignants",
  },

  cantine: {
    modifierMenu: "Modifier le menu ",
    cantine: "Menu cantine",
    dateChoisie: "Date choisie",
    choisirDate: "Choisir une date pour insérer le menu ",
    ajouterRepas: "Ajouter un repas",
    ajouterMenu: " Ajouter un nouveau menu pour",
    choisirJour: "Choisir les jours",
    nom: "Nom",
    type: " Type",
    repeter: "Répéter",
    saisirNom: " Saisir le nom du repas",
    saisirType: "Choisir le type du repas",
    plat: "Plat Principal",
    boissons: "Boissons",
    autre: "Autres",
    dessert: "Dessert",
    menuIntrouvable: "Menu introuvable, merci d'ajouter des repas !",
    ajouterNewMenu: "Ajouter nouveaux menu pour {date}",
    modifierMenu: "Modifier le menu {name}",
  },
  paiement: {
    reducation: "Réducation",
    reducationRep: "Réduction répétitive",
    reductionBase: "Réduction de base",
    selectMonths: "Sélectionner les mois",
    reducationDefault: "Réduction par défaut",
    avoir: "Avoir",
    note: "Note",
    paiementClasse: "Paiement/Classe",
    nom: "Nom de l'élève",
    classe: "Classe",
    parents: "Parents",
    tels: "Téléphones des parents",
    etat: "Etat de paiement",
    fraisInscri: "Frais d'inscription",
    fraisMensuel: "Frais Mensuels",
    dateDebut: " Date de début",
    dateFin: "Date de fin",
    tousMois: "Tous les mois",
    mois: "Mois",
    tousClasses: "Tous les classes",
    order: "Order",
    service: "Service",
    nbEleves: "Nombre d'élèves",
    listeClasses: " Liste des classes",
    listeEleves: "Liste des élèves",
    imprimerTab: "Imprimer le tableau",
    selectionnerMois: "Sélectionner le mois",
    paye: "Payé",
    inscriPaye: "Inscription payé",
    inscriNonPaye: "Inscription non payé",
    impaye: "Non payé",
    selectionnerDate: "Sélectionner une date",
    reduction: "Réducation sur l'inscription",
    reductionMensuel: "Réduction mensuelle",
    activite: "Activité",
    annulation: "Annulation",
    listeActivites: "Liste des activités",
    dt: " DT",
    non: "Non",
    chercher: "Chercher",
    annulerInscri: "voulez-vous annuler l'inscription?",
    eleveRequis: "Un élève au moins est requis",
    activiteRequis: "L'activité est requis",
    dateDebutRequis: "La date de début est requis",
    dateFinRequis: "La date de fin est requis",
    msgReduction:
      "Réglez la réduction à zéro si vous ne souhaitez pas ajouter de réduction",
    typeDepense: "Le type de dépense est requis",
    eleve: "L'élève est requis",
  },

  inscription: {
    nom: "Nom complet",
    dateNaissance: "Date de naissance",
    sexe: "Sexe",
    tels: "Téléphones des parents",
    parent: "Parent",
    nouvInscri: "Nouvelle inscription",
    nbEleves: "Nombre d'élèves: {number}",
    ficheParentClasse: "Fiche classe / parent",
    ficheClasse: "Fiche classe",
    inscription: "Inscription",
    enfant: "Enfant",
    fraisInscri: "Frais d'inscription",
    fraisMensuel: "Frais mensuels",
    remise: "Remise ",
    remiseInscri: "Remise à l'inscription",
    remiseMensuel: "Remise mensuelle",
    rechercher: "Rechercher",
    mensuel: "Mensuel",
    chercherParActivite: "Chercher par nom d'activité",
    sureToInscri: "Êtes-vous sûr d'inscrire cet Élève ?",
  },

  punition: {
    eleve: "Elève",
    nom: "Nom",
    prenom: "Prénom",
    details: "Détails",
    punition: "Punition",
    temps: "Temps",
    raison: "Raison",
    description: "Description",
    validation: "Validation",
    valides: "Validé",
    rejetes: "Rejeté",
    nonEvalues: "Non évalué",
    dateAjout: "Date d'ajout",
    dateEvalution: "Date d'évalution",
    valider: "Valider",
    rejeter: "Rejeter",
    rejectReason: "La raison du rejet de la punition",
    reasonRequired: "La raison du rejet est requise",
  },

  days: {
    sunday: "Dimanche",
    monday: "Lundi",
    tuesday: "Mardi",
    wednesday: "Mercredi",
    thursday: "Jeudi",
    friday: "Vendredi",
    saturday: "Samedi",
  },

  emploi: {
    validated: "L'emploi est maintenant disponibles pour la classe {name}",
    unvalidated: "L'emploi ne est plus disponibles pour la classe {name}",
    hideEmploin:
      "L'emploi de cette classe  ne sera plus disponibles pour les parents",
    validateEmploin:
      "L'emploi de cette classe sera disponibles pour les parents",
    emplois: "Emplois de temps",
    temps: "Temps",
    trimestre: "Triméstre",
    listeClasses: "Liste des classes",
    listeEnseignants: "Liste des enseignants",
    emploiEnseignants: " Emplois de temps des enseignants ",
    imprimerEmploi: "  Imprimer l'emploi ",
    imprimerEmploiAdministrative: "Imprimer l'emplois administrative",
    enseignant: "Enseignant",
    matiere: "Matière",
    salle: "Salle",
    duree: "Durée",
    classe: "Classe",
    choisirEnseignant: "Choisir un enseignant ",
    choisirMatiere: "Choisir une matière",
    chooseSubmodule: "Choisir un sous-module",
    choisirSalle: "Choisir une salle",
    choisirDuree: "Choisir une durée",
    choisirClasse: "Choisir une classe",
    ajouterSeance: "Ajouter une séance ",
    modifierSeance: "Modifier une séance ",
    disponible: "Disponible",
    indisponible: "Indisponible",
    modeModif: "Mode modification est activé !",
    desc: "Vous pouvez modifer l'emplacement et la durée des séances !",
    totaleHeures: "Totale des Heures",
    moyenneHeuresJour: "Moyenne des Heures/jour",
    maximunHeuresJour: "Maximum des Heures/jour",
    totaleClasses: "Totale des classes",
    totaleTeachers: "Totale des enseignants",
    seance: "Séance",
    seanceListe: "Liste des séances",
    choisirName: "Choisir un nom pour la séance",
    name: "Nom de la séance",
    includedSubjects: "L'enseignant peut accéder",
  },
  bulletin: {
    useSubModule: "Utiliser sous-modules",
    subModule: "Sous-modules",
    managerSubModule: "Gérer les sous-modules",
    manageCertType: "Gérer les certificates pour :",
    defaultModule: "Modules par défaut",
    defaultModuleConfirm:
      "Cette action supprimera le module existant. Voulez-vous continuer avec le paramétrage des modules par défaut ?",
    defaultDone: "Les modules par défaut sont enregistrés avec succès",

    coefTrimester1: "Coefficient du trimestre 1",
    coefTrimester2: "Coefficient du trimestre 2",
    coefTrimester3: "Coefficient du trimestre 3",
    moyenneGeneral: "Moyenne générale",
    bulletinCoefs: "Coefficients des bulletins",
    coefBulletinsSaved:
      "Les coefficients des bulletins enregistrés avec succès",

    decisionConseil: "Décision du conseil",
    finalBulletin: "Bulletin annuel",
    syncClass: "Synchroniser par classe",
    syncSubject: "Synchroniser par matiére",
    syncMarks: "Synchroniser les notes",
    sureToSync: "Voulez vous écraser les notes ?",
    syncDone: "Notes synchronisées avec succès",
    date: "Date d'impression",
    parametrageSaved: "Le bulletin paramètres enregistrés avec succès",
    label: "Libellé",
    moyennePersonalized: "Moyenne personnalisé par école",
    moyenneSpecifique: "Moyenne Pilote",
    moyennePedagogique: "Moyenne Pédagogique",
    moyenneVerySpecifique: "Moyenne spécifique",
    formulePersonalized: "Formule personnalisé",
    imprimerFichModuleVide: "Fiche vide",
    imprimerFichModule: "Fiche remplie",
    certByMoyenne: "Certificats selon moyens éléves",
    imprimerBulletin: "Imprimer le  bulletin",
    note: "Note",
    nom: "Nom de l'élève",
    bulletin: "Bulletin",
    bulletinV1: "Bulletin (Ancienne version)",
    notes: "Notes",
    matieres: "Matières",
    bulletinPedagogique: "Bulletin Pédagogique",
    bulletinSpecifique: "Bulletin Pilote",
    bulletinVerySpecifique: "Bulletin Spécifique",

    performancesMatiere: "Performances des matière",
    plus15: "Plus de 15 ",
    plus12: "Plus de 12 ",
    plus9: "Plus de 9 ",
    mois9: "Moins de 9 ",

    listeClasses: "Liste des classes",
    classesActives: "Classes actives",
    nombreClassesNonNote: "Nombre de classes non noté",
    tousClassesSontNotes: "Toutes les classes sont notées !",
    nombreMatieresNonNote: "Nombre de matières non noté",
    tousMatieresSontNotes: "Toutes les matières sont notées !",
    nombreElevesNonNotes: "Nombre de élève non noté",
    tousElevesSontNotes: "Toutes les élèves sont notées !",

    classes: "classes",
    toutMatieres: "Toutes les matières",
    enregistrerTout: "Enregistrer tout",

    typeIntrouvable: "Type introuvable",
    saisirSchoolType:
      "Merci de saisir le type de votre établissement dans le paramétrage - établissement !",

    confirmPrintMatieres:
      "Les notes des matières ci-dessous ({count}) doit être remplis pour générer le bulletin des notes !",
    confirmPrintEleves:
      "Les notes des élèves ci-dessous ({count}) doit être remplis pour générer le bulletin des notes !",
    generateAnyway: "Générer quand même",

    listeClassesNonNote: "Liste de classes non noté",
    listeMatiereNonNote: "Liste de matières non noté",
    listeEleveNonNote: "Liste d'élève non noté",

    performancesNotes: "Performances des notes",
    notePlusHaute: "Note la plus haute",
    noteEleve: "Note d'élève",
    notePlusBasse: "Note la plus basse",

    moyennesModules: "Moyennes des modules",

    maximaleMoyenne: "Maximale moyenne",
    moyenne: "Moyenne",
    minimaleMoyenne: "Minimale Moyenne",
    imprimerTout: "Imprimer tout",

    moyenneTrimestre: "Moyenne de trimestre : {moyenne}",
    moyenneMax: "Moyenne la plus haute : {moyenne}",
    moyenneMin: "Moyenne la plus basse : {moyenne}",

    validated:
      "Les bulletins sont maintenant disponibles pour la classe {name}",
    unvalidated: "Les bulletins ne sont plus disponibles pour la classe {name}",
    hideBulletin:
      "Les bulletins de cette classe  ne seront plus disponibles pour les parents",
    validateBulletin:
      "Les bulletins de cette classe seront disponibles pour les parents",
    cancelValidation: "Mettre indisponible pour les parents",
    validatedClassroom: "Mettre disponible pour les parents",
    deleteMark: "Voulez-vous supprimer la note ?",
    markDeletedSucc: "Note a éte supprimé avec succès",
    markDeletedFail: "On ne peut pas supprimer cette note, merci de résseyer !",
  },

  personnel: {
    nom: "Nom",
    prenom: "Prénom",
    adresse: "Adresse",
    telephone: "Téléphone",
    qualification: "Qualification",
    dateNaissance: "Date de naissance",
    typeContrat: "Type du contrat",
    salaire: "Salaire",
    cin: "CIN",
    etatCivil: "Etat civil",
    niveau: "Niveau",
    experiences: "Expériences",
    dateEntretien: "Date de l'entretien",
    qualifications: "Qualifications",
    tauxHoraire: "Taux horaire",
    detailPaiement: "Détails de paiement",
    jourSemaine: "Jours par semaine",
    cnss: "CNSS",
    rib: "RIB",
    autre: "Autre",
    notes: "Notes",
    infoBase: "Informations de base",
    infoContrat: "Informations sur le contrat",
    chercher: "Chercher",
    listeQualifications: "Liste des qualifications",
    staff: "Staff",
  },

  enseignant: {
    advisorAddExercice: "Possibilité d'ajouter des exercices",
    nom: "Nom",
    prenom: "Prénom",
    adresse: "Adresse",
    telephone: "Téléphone",
    qualification: "Qualification",
    dateNaissance: "Date de naissance",
    typeContrat: "Type du contrat",
    salaire: "Salaire",
    cin: "CIN",
    etatCivil: "Etat civil",
    niveau: "Niveau",
    experiences: "Expériences",
    dateEntretien: "Date de l'entretien",
    qualifications: "Qualifications",
    tauxHoraire: "Taux horaire",
    detailPaiement: "Détails de paiement",
    jourSemaine: "Jours par semaine",
    cnss: "CNSS",
    rib: "RIB",
    autre: "Autre",
    notes: "Notes",
    infoBase: "Informations de base",
    infoContrat: "Informations sur le contrat",
    chercher: "Chercher",
    listeQualifications: "Liste des qualifications",
    matiere: "Matière",
    specialite: "Spécialité",
    grade: "Grade",
    email: "Email",
    mdp: "Mot de passe",
    compte: "Compte",
    detailPedagogiques: "Détails pédagogiques",
    usernameEmail: "Nom d'utilisateur/email",
    photo: "Photo",
    conseillersPedagogiques: "Les conseillers pédagogiques",
    ajouterConseillerPedagogique: "Ajouter un conseiller pédagogique",
    enterInfoConseillerPedagogique:
      "Entrez les informations du conseiller pédagogique dans les champs ci-dessous",
    niveauxSuperviser: "Les niveaux a supervisés",
    niveauxRequis: "Les niveaux sont requis",
    listeNiveaux: "Liste des niveaux",
    aucunConseillerPedagogique: "Aucun Conseiller pédagogique est trouvé",
    needValidation: "validation automatique des cours et des exercises",
    needValidation2: " et des exercises",
  },

  etatPaiement: {
    personnel: "Personnel",
    salaire: "Salaire",
    avance: "Avance",
    nbJours: "Nombre de jours",
    mois: "Mois",
    etatPaiement: "Etat de paiement",
    virementBancaire: "Virement bancaire ?",
    paiementAction: "Paiement",
    selectionnerMois: "Sélectionner un mois",
  },
  virement: {
    rib: "RIB",
    nom: "Nom",
    prenom: "Prénom",
    etatPaiement: "Etat de paiement",
    salaire: "Salaire",
    mois: "Mois",
    nbJours: "Nombre de jours",
    chercher: "Chercher",
  },

  recette: {
    fournisseurProfil: "Profil du fournisseur",
    checksDueThisWeek: "Les chèques qui échéance cette semaine",
    dateAjout: "Date d'ajout",
    doneBy: "Effectué par",
    cancelledBy: "Annulé par",
    montantRequis: "Le montant est requis et doit étre un chiffre",
    montantCheque: "Montant chèque",
    montantEspece: "Montant espèce",
    montantTotal: "Montant total",
    typePaiement: "Type de paiement",
    montant: "Montant",
    nom: "Nom du client",
    typeRecete: "Type du recette",
    typeReglement: "Type du règlement",
    modePaiement: "Mode de paiement",
    receiptType: "Type Recette",
    montantHT: "Montant HT",
    montantTTC: "Montant TTC",
    type: "Type",
    datePaiement: "Date de paiement",
    numCheque: "Numéro du chèque",
    imprimer: "Imprimer ",
    csv: "CSV",
    datePaiement: "Date de paiement",
    tous: "Tous",
    cheque: "Chèque",
    espece: "Espèce",
    chequeEtEspece: "Chèque et espèce",
    detailsCheque: "Détails du chèque",
    numero: "Numéro",
    titulaire: "Titulaire",
    banque: "Banque",
    dateEcheance: "Date d'échéance",
    chequeMontantTotal: "Montant total des chèques",
    relatedPayments: "Paiements liés",
    fermer: "Fermer",
    ok: "Ok",
    typeJournal: "Type du journal",
    selectionnerDate: "Sélectionner une date",
    motCles: "Mots clés",
    chercher: "Chercher",
    bouillard: "Bouillard caisse",
    annule: "Annulé",
    personnel: "Personnel",
    salaire: "Salaire",
    avance: "Avance",
    nbJours: "Nombre de jours",
    mois: "Mois",
    etatPaiement: "Etat de paiement",
    virementBancaire: "Virement bancaire ?",
    typeDepense: "Type de dépense",
    date: "Date",
    fournisseur: "Fournisseur",
    numSerie: "Numéro de série",
    nomParent: "Nom du parent",
    dateAnnulation: "Date d'annulation",
    facture: "Facture ?",
    impression: "Impression",
    annulation: "Annulation",
    paiementQuoi: "Paiement sur quoi",
    selectionnerParent: "Sélectionner le parent",
    selectionnerEnfant: "Sélectionner l'enfant",
    titre: "Titre",
    valider: "Valider",
    recuPaiement: "Reçu de paiement",
    facturePaiement: "Facture de paiement",
    paiementTotal: "Paiement Total ?",
    paiementPour: "Paiement pour ",
    restePayer: "Reste à payer",
    payer: "Payer",
    payerMain: "Payer maintenant ?",
    reducRepet: "Réduction par défaut",
    reducFact: "Réduction Sur cette facture",
    reducInscri: "Réduction sur Inscription",
    reductionMensuel: "Réduction mensuelle",
    fraisInscri: "Frais d'inscription",
    fraisMensuel: "Frais mensuels",
    ajouterPaiement: "Ajouter un paiement",
    montantRestant: "Montant restant",
    inscription: "Inscription",
    telephone: "Téléphone",
    frais: "Frais",
    enfant: "Enfant",
    activite: "Activité",
    tousMois: "Tous les mois",
    montantHTVA: "Montant HTVA",
    nonSeule: "Non (une seule fois)",
    payeQues: "Payé ?",
    recus: "Récus",
    factureTag: "Facture",
    avantReduction: " Avant réduction",
    apresReduction: " Aprés réduction",
    reduction: " Réduction",
    saisirInfoCheque: "Saisir les informations du chèque",
    saisirInfoChequeEtEspace: "Saisir les informations des chèques et espéce",
    timbreFiscal: "Timbre Fiscal",
  },

  choisir: {
    enseignant: "Choisir un enseignant",
    matiere: "Choisir une matière",
    salle: "Choisir une salle",
    duree: "Choisir une durée",
    classe: "Choisir une classe",
    repas: "Choisir le type du repas",
    jour: "Choisir les jours de ce repas",
    dateDebut: "Choisir la date de début",
    dateFin: "Choisir la date de fin",
    niveauClass: "Choisir le niveau du classe",
    template: "modèle",
  },

  requis: {
    template: "Le modèle est requis",
    smsContenue: "La contenue de votre message est requis.",
    message: "Le message est requis.",
    coefficient: "Le coefficient est requis",
    red: "Réglez la réduction à zéro si vous ne souhaitez pas ajouter de réduction",
    activite: "L'activité est requise",
    cnss: "Le CNSS est requis",
    rib: "Le RIB est requis",
    specialite: "La spécialité est requise",
    niveau: "Le niveau est requis",
    etatCivil: "L'état civil est requis",
    jourSemaine: "Les jours par semaine sont requis ",
    tauxHoraire: "Le taux d'horaire est requis",
    dateEntretien: "La date d'entretien est requise",
    matiere: "La matière est requise",
    typeContrat: "Le type du contrat est requis",
    qualification: "La qualification est requis",
    telephone: "Numéro de téléphone doit étre composer de 8 chiffres",
    cin8: "Numéro de carte d'identité doit étre composer de 8 chiffres",
    cin: "Numéro de carte d'identité national est requis",
    pourcentage: "Le pourcentage de remise est requis",
    dinar: "Le montant en dinar est requis",
    order: "L'ordre est requis",
    mail: "Saisir votre mail !",
    mois: "Le mois est requis",
    mdp: "Le mot de passe est requis",
    noWhitespace: "Le champ ne peut pas contenir des espaces",
    confirmation: "La confirmation de mot de passe est requise",
    username: " Le nom d'utilisateur est requis",
    usernameEmail: " Le nom d'utilisateur ou email est requis",
    titre: "Le titre est requis ",
    categorie: "La catégorie est requise",
    designation: "La désignation est requise",
    cible: " Le cible est requis",
    salaire: "Le salaire est requis",
    role: "Le role est requis",
    repas: "Saisir le nom du repas",
    depense: "Le type de dépense est requis",
    fournisseur: "Le fournisseur est requis",
    nom: " Le nom est requis ",
    min: " La note minimale est requise ",
    max: " La note maximale est requise",
    nomPersonnel: "Le nom du personnel est requis",
    date: "La date est requise",
    selection: "La sélection des parents est requise",
    nomPere: "Le nom du père est requis",
    nomMere: "Le nom de la mère est requise",
    prenom: "Le prénom est requis",
    prenomPere: "Le prénom du père est requis",
    prenomMere: "Le prénom de la mère est requis",
    telPere: "Le téléphone du père est requis",
    telMere: "Le téléphone de la mère est requis",
    email: "E-mail non valide ! ",
    activite: "L'activité est requise ",
    carac: "4 caractère au minimum pour le mot de passe ",
    genre: "Le genre est requis",
    adresse: "L'adresse est requise",
    service: "Un service doit etre séléctionné",
    fraisInscri: "Le frais d'inscription est requis",
    fraisMensuel: "Les frais mensuel sont requis.",
    ajoutLogo: "Ajouter le nouveau logo ",
    coefficient: "Le coefficient est requis",
    dateNaissance: "La date de naissance est requise",
    nbJours: "Le nombre de jours est requis",
    montant: "Le montant est requis et doit étre un chiffre",
    avance: " L'avance est requise",
    selectionEnfant: "La sélection de l'enfant est requise",
    etatPaiement: "L'état de paiement est requise",
    specification: "La spécification est requise",
    observation: "L'observation est requise",
    reductionInscro:
      "Réduction sur le frais d'inscription est requis (mettre zéro si il n'y a pas de reduction",
    reductionMensuel:
      "Réduction sur le frais mensuel est requis (mettre zéro si il n'y a pas de reduction)",
    supp: "Êtes-vous sûr de supprimer ?",
    supp2:
      "Si vous supprimez un élève son état de paiement sera mis à jour, tous les mois non payé seront supprimés. Sûr de supprimer?",
    annu: "Si vous annulez l'inscription, l'état de l'inscription de l'Ève le pour l'année scolaire courante sera mis à jour. Sûr de d'annuler l'inscription?",
    appreciation: "L'appréciation est requise",
  },

  success: {
    appreciationOptionAjout: "L'appréciation a été ajoutée",
    appreciationOptionMSA: "L'appréciation a été mise à jour",
    appreciationOptionSupp: "L'appréciation a été supprimée",
    certificationAjout: "La certification a été ajoutée",
    certificationMSA: "La certification a été mise à jour",
    certificationSupp: "La certification a été supprimée",

    decisionConseilAjout: "La décision du conseil a été ajoutée",
    decisionConseilMSA: "La décision du conseil a été mise à jour",
    decisionConseilSupp: "La décision du conseil a été supprimée",

    noteEnregistre: "Le note enregistrées avec succès ",
    noteToutesEnregistre: "Toutes les notes enregistrées avec succès",
    noteRemoved: "La note a été supprimée avec succès",

    smsEnvoye: "SMS envoyé avec succès",

    modificationsEnseignantsEffectue:
      "La modifications des enseignants est efféctué!",
    modificationsMatieresEffectue:
      "La modifications des matiéres est efféctué!",
    cacheAjout: "Le caché téléchargé avec succès.",
    cacheSupp: "Le caché a été supprimé avec succès",

    inscriAjout: "L'inscription a été ajoutée",
    inscriSupp: "L'inscription a été supprimée",
    inscriAnnule: "L'inscription a été annulée",

    pubAjout: "La publication a été ajoutée",
    pubSupp: "La publication a été archivée",

    photoAjout: "La photo a été ajoutée",
    compte: "Le type de compte a été ajouté",

    autorisationMSA: "L'autorisation a été mise à jour",
    autorisationSupp: "L'autorisation a été supprimée ",

    utilisateurAjout: " L'utilisateur a été ajouté",
    utilisateurMSA: " L'utilisateur a été modifié",
    utilisateurSupp: " L'utilisateur a été archivé",
    utilisateurDesac: "L'utilisateur a été désactivé ",
    utilisateurActiv: " L'utilisateur a été activé",

    repasAjout: "Le repas a été ajouté",
    repasMSA: "Le repas a été modifié",
    repasSupp: "Le repas a été supprimé",

    depenseMSA: "La dépense a été mise à jour",
    depenseSupp: "La dépense a été archivée",

    reglementSupp: "Le réglement a été supprimé",
    paiementAjout: "Le paiement a été ajouté",
    dossierMSA: "Le dossier a été mis à jour",
    dossierSupp: "Le dossier médical a été supprimé",

    buildingMSA: "Le bâtiment {name} a été mis à jour avec succès",

    eleveAjout: "L'élève a été ajouté",
    eleveMSA: "L'élève a été mis à jour",

    bulletinMSA: " Le bulletin a été modifié ",

    typeDepenseAjout: "Le type de dépense a été ajouté",
    typeDepenseModif: "Le type de dépense a été modifié",
    typeDepenseArchiv: "Le type de dépense a été archivé",

    fournisseurAjout: "Le fournisseur a été ajouté",
    fournisseurMSA: "Le fournisseur a été modifié",
    fournisseurArchiv: "Le fournisseur a été archivé",

    typeRecetteMSA: "Le type de recette a été modifié",
    typeRecetteAjout: "Le type de recette a été ajouté",
    typeRecetteArchiv: "Le type de recette a été archivé",

    activiteAjout: " L'activité a été ajoutée",
    activiteSupp: "L'activité a été archivée",
    activiteMSA: "L'activité est modifiée",

    emploiMSA: "L'emplois est modifiée",

    logoMSA: " Le logo a été modifié",

    fileUploadedPleaseSave:
      "{name} fichier téléchargé avec succès, veuillez sauvegarder!",

    seanceAjout: " La séance a été ajoutée",
    seanceMSA: "La séance a été modifiée",
    seanceSupp: " La séance a été archivée",

    punitionValid: " La punition est évalué",

    personnelAjout: " Le personnel a été ajouté ",
    personnelMSA: "Le personnel a été modifié",
    personnelSupp: "Le personnel a été archivée ",
    personnelNonSupp: "Le personnel n'a pas été supprimé",

    enseignantAjout: "L'enseignant a été ajouté ",
    enseignantMSA: "L'enseignant a été modifié",
    enseignantSupp: "L'enseignant a été supprimé ",
    enseignantNonSupp: "L'enseignant n'a pas été supprimé",

    classeAjout: "La classe a été ajoutée",
    classeMSA: "La classe a été modifiée",
    classeSupp: "La classe a été archivée",

    moduleSupp: "Le module a été supprimé",

    typePunitionAjout: "Le type de la punition a été ajouté",
    typePunitionMSA: "Le type de la punition a été modifié",
    typePunitionArchiv: "Le type de la punition a été archivé",

    typeQualificationAjout: "Le type de la qualification a été ajouté",
    typeQualificationMSA: "Le type de la qualification a été modifié",
    typeQualificationArchiv: "Le type de la qualificationa été archivé",

    matiereAjout: "La matière a été ajoutée",
    submoduleAdded: "Le sous-module a été ajouté",
    matiereMSA: "La matière a été modifiée",
    matiereSupp: "La matière a été archivée",

    anneeAjout: "L'année scolaire a été ajoutée",
    anneeMSA: "L'année scolaire a été modifiée",
    anneeSupp: "L'année scolaire a été archivée",

    serviceAjout: "Le service a été ajouté ",
    serviceMSA: "Le service a été modifié",
    serviceSupp: "Le service a été supprimé ",

    salleAjout: "La salle a été ajoutée",
    salleMSA: "La salle a été modifiée",
    salleSupp: "La salle a été archivée",

    eleveAjout: "L'élève a été ajoutée",
    eleveMSA: "L'élève a été modifiée",
    eleveSupp: "L'élève a été archivée",
    eleveSupp2: "L'élève a été supprimé",
    eleveRective: "L'élève a été réactivée",

    parentAjout: "Le parent a été ajoutée",
    parentMSA: "Le parent a été modifiée",
    parentSupp: "Le parent a été archivée",

    eleveAjoutAuClasse: "La élève ajouté au classe avec succées !",
    eleveDemeangeAuClasse: "La élève déménagé au {name} avec succès",

    InscriptionAnnulee: "Inscription annulée avec succès.",

    imageTelecharge: "{name} fichier téléchargé avec succès.",

    conseillerPedagogiqueAjout: "Conseiller pédagogique a été ajoutée",
    conseillerPedagogiqueMSA: "Conseiller pédagogique a été modifiée",
    conseillerPedagogiqueSupp: "Conseiller pédagogique a été archivée",
  },

  error: {
    allSubModuleNeedToBeSelected: "Selectionner tous les sous-modules",
    noSubMoudoleIsfound: "Aucun sous-module is trouvé",
    impoAjoutAppreciationOption: "Impossible d'ajouter une appréciation",
    aucCert: "Aucune certification n'a été trouvée",
    impoAjoutCertification: "Impossible d'ajouter la certification",
    impoEnregistrerVide: "Impossible d'enregistrer une valeur vide",
    invalidNote: "Note invalide !. Doit être compris entre 0 et 20",

    smsFormat:
      "Format invalide, les numéros de téléphone doivent être séparés par des virgules !",

    eleveDejaInscritPourMois: "Elève déjà inscrit pour les mois selectionnés",
    impoModifiEleve: "Impossible de mettre à jour l'élève",
    impoModifi: "Impossible de mettre à jour",
    eleveNonAjoutAuClasse:
      "Inscription non ajoutée, veillez annulez l'inscription puis affecter l'eleve à un nouveau classe !",
    InscriptionNonModifi:
      "Inscription non modifiée, veillez annulez l'inscription puis affecter l'eleve à un nouveau classe !",

    matiereNonAffecte:
      "Toutes les matières et son coefficient doivent être remplies  !",
    subsubjectNonAffecte:
      "{subject}: Toutes les sous-matières doivent être remplies !",
    capacite: " La capacité de la salle est inférieur aux nombre des élèves",
    fichiers: "Vous ne pouvez télécharger que des fichiers JPG ,JPEG ou PNG!",
    classe: "classe non trouvée",
    activite: "Activité non trouvée",
    service: "Service non trouvé",
    inscription: "Inscription ",
    aucScol: "Aucune inscription au scolarité est trouvée",
    aucClasse: "Aucune classe est trouvé",
    aucEleve: "Aucune élève est trouvé",
    aucInscri: "Aucune inscription au activité est trouvé",
    aucPersonnel: "Aucun personnel est trouvé",
    aucService: "Aucun service est trouvé",
    aucActivite: "Aucune activité est trouvée",
    aucActualite: "Aucune actualité est trouvée",
    aucRole: "Aucun rôle est trouvé",
    aucEtablissement: "Aucun établissement est trouvé",
    aucRecette: "Aucun type de recette est trouvé",
    aucMatiere: "Aucune matière est trouvée",
    aucUser: "Aucun utilisateur est touvé",
    aucFournisseur: "Aucun fournisseur est trouvé",
    aucDepense: "Aucune dépense est trouvée",
    aucQualification: "Aucun type de qualification est trouvé",
    aucEnseignant: "Aucun enseignant est trouvé",
    aucAnnee: "Aucune année scolaire est trouvée",
    aucSalle: "Aucune salle est trouvé",
    aucModule: "Aucun module est trouvé",
    verifier: "Vérifiez les mots de passe",
    modif: "Une erreur dans la modification",
    ajout: "Une erreur dans l'ajout",
    supp: "Une erreur dans la suppression",

    impoInscriSupp: "Impossible de supprimer cette inscription",
    impoPubAjout: "Impossible d'ajouter la publication",
    impoPubSupp: "Impossible de supprimer la publication",
    impoAjoutType: "Impossible d'ajouter ce type",
    impoAjoutActivite: "Impossible d'ajouter l'activité",
    impoAjoutAnnee: "Impossible d'ajouter l'années scolaire",
    impoAjoutClasse: "Impossible d'ajouter la classe",
    impoAjoutSalle: "Impossible d'ajouter la salle",
    impoAjoutService: "Impossible d'ajouter la service",
    impoAjoutFournisseur: "Impossible d'ajouter ce fournisseur",
    impoAjoutCeType: "Impossible d'ajouter ce type",
    impoModifiMatieres: "Impossible de modifier les matiéres",
    impoModifiEnseignants: "Impossible de modifier les enseignants",

    image: "L'image doit être inférieure a 5 MO ",
    image50: "Les images doivent être inférieure à 50 MO ",
    maxImage: "Le nombre maximal des images est 15",
    nonAjoute: "Non ajouté",

    autorisationNonMSA: "Autorisation n'a pas été mise à jour",
    autorisationNonSupp: "Autorisation non supprimée",

    eleveNonMSA: " L'élève n'a été mis à jour",
    impoTelecharger: " Impossible de télécharger l'image",

    dossier: "Impossible de trouver le dossier de l'élève ",
    dossierNonMSA: " Le dossier n'a pas été mis à jour",

    nonValid: "vous ne pouvez pas valider un paiement d'un montant égal à zéro",

    recetteNonAjoute: "recette non ajouté",
    recetteNonMSA: "recette n'a pas été mis à jour",
    recetteNonSupp: "recette n'a pas été supprimé",

    qualificationNonAjoute: "qualification non ajouté",
    qualificationNonMSA: "Qualification n'a pas été mis à jour",
    qualificationNonSupp: "Qualification n'a pas été supprimé",

    serviceNonSupp: "Cette service ne peut être supprimé",

    erreur: "Une erreur est survenu",

    depenseNonMSA: "Dépense n'a pas été mise à jour",
    depenseNonSupp: "Dépense non supprimée",

    buildingEchoue: "Le mise à jour bâtiment {name} a échoué",

    inscriEchoue: "L'inscription est échouée",
    personnelNonAjoute: "Personnel non ajouté",
    personnelNonMSA: "Personnel n'a pas été mis à jour",

    enseignantNonAjoute: "Enseignant non ajouté",
    enseignantNonMSA: "Enseignant n'a pas été mis à jour",

    listeNonTrouve: "Liste des élèves non trouvée",

    anneeNonAjoute: "Année non ajouté",
    anneeNonMSA: "Année n'a pas été mis à jour",

    punitionNonAjoute: "Punition non ajouté",
    punitionNonMSA: "Punition n'a pas été mis à jour",

    serviceNonAjoute: "service non ajouté",
    serviceNonMSA: "Ce service ne peut être modifié",

    depenseNonAjoute: "dépense non ajouté",
    depenseNonMSA: "dépense n'a pas été mis à jour",

    fournisseurNonAjoute: "Fournisseur non ajouté",
    fournisseurNonMSA: "Fournisseur n'a pas été mis à jour",
    fournisseurNonSupp: "Cette fournisseur ne peut être supprimé",

    activiteNonMSA: "L'activité ne peut être modifé, veuillez réessayer!",

    moduleNonAjoute: "Module non ajouté",
    moduleNonMSA: "Module n'a pas été mis à jour",
    moduleNonSupp: "Ce module ne peut être supprimé",
    personnelNonMSA: "Personnel n'a pas été mis à jour!",
    personnelNonAjoute: "Personnel non ajouté",
    matiereNonAjoute: "Matière non ajouté",
    subModuleNonAjoute: "Sous-module non ajouté",
    matiereNonMSA: "Cet matière ne peut être supprimée",
    listeNonTrouve: "Liste des élèves non touvée !",
    inscrEchoue: "L'inscription est échouée",
    activiteNonSupp: "L' activité ne peut pas être supprimée",
    classeNonSupp: "La classe ne peut pas être suppprimée",
    classeIndis: "La classe est indisponible ",
    salleIndis: "La salle est indisponible",
    enseignantIndis: "L'enseignant est indisponible",
    selectEnseignant: "Sélectionner un enseignant",
    selectSeance: "Sélectionner le séance",
    selectClasse: "Sélectionner une classe ",
    sallePriseEntre:
      "La salle {room} est prise par la classe {name} entre {start} et {end}",
    classPriseEntre: "La classe {name} est prise entre {start} et {end}",
    enseignantEnseigneClasse:
      "L'enseignant {fullname} enseigne la classe {name} la matiere {subject} entre {start} et {end}",
    anneeNonSupp: "L'année ne peut pas être supprimée",

    utilisateurNonAjoutDiff:
      "Utilisateur non ajouté, merci d'essayer avec un nom d'utilisateur different !",

    conseillerPedagogiqueNonAjoute: "Conseiller pédagogique non ajouté",
    conseillerPedagogiqueNonMSA:
      "Conseiller pédagogique n'a pas été mis à jour",
    conseillerPedagogiqueNonSupp: "Conseiller pédagogique n'a pas été supprimé",
    invalidFormat: "Format de fichier invalide",
  },

  warning: {
    verifieDonnees: "Vérifier les données",
    connectionProblem:
      "Un problème de la connexion, veuillez rafraîchir la page !",
    selectModePaiement: "Selectionner le mode de paiement",
    paiementNonEffectuer: "Paiement non effectuer",
    invalidMarks:
      "Veuillez vous assurer que vos notes sont comprises entre 0 et 20.",
    notCorrectMarks:
      "Veuillez vous assurer que vos notes ont été saisies correctement.",
    missedSubsubjects:
      "Veuillez compléter toutes les notes manquantes pour ({name})",
  },
};

export default {
  locale: "fr-FR",
  localeAntd,
  messages,
};
